import { PROXY_TREE, VALUE } from 'proxy-state-tree'

import { IState } from '.'

type TStates = IState & {
  state: string | number,
}

export type StatemachineTransitions<States extends TStates> = {
  [State in States["state"]]: Array<States["state"]>
}

export interface MachineMethods<States extends TStates> {
  matches<T extends States["state"]>(state: T): this is Statemachine<States, States extends {
    state: T;
} ? States : never>;
  transition<State extends States["state"]>(
    state: State,
  ): this is Statemachine<States, States extends {
    state: State
} ? States : never>
}

export type Statemachine<States extends TStates, State extends TStates = States> = State & MachineMethods<States>

const INITIAL_STATE = Symbol('INITIAL_STATE')
const TRANSITIONS = Symbol('TRANSITIONS')

class StateMachine<States extends TStates, State extends TStates = States>  {
  state: State["state"]
  private [INITIAL_STATE]: State["state"]
  constructor(transitions: StatemachineTransitions<States>, definition: States) {
    this[INITIAL_STATE] = definition.state
    this[TRANSITIONS] = transitions
    Object.assign(this, definition)
  }
  transition(state) {
    const transitions = this[VALUE][TRANSITIONS]
    if (transitions[this.state].includes(state)) {
      const tree = (this[PROXY_TREE].master.mutationTree || this[PROXY_TREE])
      tree.enableMutations()
      this.state = state
      Promise.resolve().then(() => tree.blockMutations())
      return true
    } else if (process.env.NODE_ENV === 'development') {
      console.warn(`Overmind Statemachine - You tried to transition into "${state}", but it is not a valid transition. The valid transitions are ${JSON.stringify(transitions[this.state])}`)
    }
    return false
  }
  matches(state) {
    if (state === this.state) {
      const tree = (this[PROXY_TREE].master.mutationTree || this[PROXY_TREE])
      tree.enableMutations()
      return true
    }

    return false
  }
}

export function statemachine<States extends TStates>(definition: StatemachineTransitions<States>, state: States): Statemachine<States> {
  return new StateMachine(definition, state) as any
}
